import React from "react";

function greetingTime() {
  const date = new Date();
  const hours = date.getHours();
  const event = new Date();

  let timeOfDay;
  if (hours < 12) {
    timeOfDay = "morning";
  } else if (hours >= 12 && hours < 17) {
    timeOfDay = "afternoon";
  } else {
    timeOfDay = "night";
  }

  return (
    <div>
      <h1>
        Good {timeOfDay}! Today is {event.toLocaleString("en-US")}
      </h1>
    </div>
  );
}

export default greetingTime;
